export const RESCUABLE_STATUS_CODES = [
  403, // forbidden; most likely stale csrf token. refresh should fix
  404, // not found; let user go back
  500, // we eff'd up; let user refresh and hope for the best
  502, // likely nginx error (often deploy/mem related)
  504, // likely nginx error (often deploy/mem related)

  // useful only when user IS supposed to be logged in but their session has
  // expired. this will show error and let them reload the page which should
  // then redirect to login.
  //
  // we don't want to do this when user is NOT logged in bc 401s in that case
  // could mean failed login and should be already handled by the login
  // component
  ...(window.__DPL_CURRENT_USER_ID ? [401] : [])
];

// queries can be grouped with with by query key prefixes
// ex) queries with keys ['apple', 'banana'] and ['apple', 'cantaloupe']
//     can be refetched with queryClient.invalidateQueries('apple')
// ex) queries with keys ['apple', 'banana', 'cantaloupe'] and ['apple', 'banana', 'durian']
//     can be refetched with queryClient.invalidateQueries(['apple', 'banana'])
export const QUERY_KEY_PREFIXES = {
  BREEDER_APP_APPLICATIONS: 'BREEDER_APP_APPLICATIONS',
  BREEDER_APP_DOG: 'BREEDER_APP_DOG',
  BREEDER_APP_DOGS: 'BREEDER_APP_DOGS',
  BREEDER_APP_FAMILIES: 'BREEDER_APP_FAMILIES',
  BREEDER_APP_LITTER: 'BREEDER_APP_LITTER',
  BREEDER_APP_LITTERS: 'BREEDER_APP_LITTERS',
  BREEDER_APP_PAYMENT_SUMMARY: 'BREEDER_APP_PAYMENT_SUMMARY',
  BREEDER_APP_PAYMENT_SETTINGS: 'BREEDER_APP_PAYMENT_SETTINGS',
  BREEDER_APP_PAYMENTS: 'BREEDER_APP_PAYMENTS',
  BREEDER_LITTER_OPTIONS: 'BREEDER_LITTER_OPTIONS',
  CONVERSATION: 'CONVERSATION',
  CONVERSATIONS: 'CONVERSATIONS',
  ADMIN_BREEDER_ACCOUNT_SCREENING_TASKS:
    'ADMIN_BREEDER_ACCOUNT_SCREENING_TASKS',
  ADMIN_SCREENING_SCORE_SHEETS: 'ADMIN_SCREENING_SCORE_SHEETS',
  ADMIN_BREEDER_ACCOUNTS: 'ADMIN_BREEDER_ACCOUNTS',
  ADMIN_TESTIMONIALS: 'ADMIN_TESTIMONIALS',
  ADMIN_TESTIMONIAL_INDEX: 'ADMIN_TESTIMONIAL_INDEX',
  ADMIN_TESTIMONIAL_REVIEW_MODAL: 'ADMIN_TESTIMONIAL_REVIEW_MODAL',
  SCREENING_QUIZ: 'SCREENING_QUIZ',
  BREEDER_APP_WAITLIST_MEMBER: 'BREEDER_APP_WAITLIST_MEMBER',
  TESTIMONIAL: 'TESTIMONIAL',
  BUYER_FAVORITE_PUPPIES: 'BUYER_FAVORITE_PUPPIES',
  BUYER_FAVORITE_BREEDERS: 'BUYER_FAVORITE_BREEDERS',
  BUYER_FAVORITE_BREEDER_BREEDS: 'BUYER_FAVORITE_BREEDER_BREEDS',
  BUYER_FAVORITE_PUPPY_BREEDS: 'BUYER_FAVORITE_PUPPY_BREEDS',
  BUYER_FAVORITES_SUMMARIES: 'BUYER_FAVORITES_SUMMARIES',
  CONTACT_EDIT: 'CONTACT_EDIT'
};

export const QUERY_KEY_PREFIX_VALUES = Object.values(QUERY_KEY_PREFIXES);
