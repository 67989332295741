export const INCREMENT_INFINITE_SCROLL_PAGE = 'INCREMENT_INFINITE_SCROLL_PAGE';
export const RESET_INFINITE_SCROLL = 'RESET_INFINITE_SCROLL';
export const SET_INFINITE_SCROLL_ITEMS = 'SET_INFINITE_SCROLL_ITEMS';
export const APPEND_INFINITE_SCROLL_ITEMS = 'APPEND_INFINITE_SCROLL_ITEMS';
export const UPDATE_INFINITE_SCROLL_ITEMS = 'UPDATE_INFINTE_SCROLL_ITEMS';

export function incrementPage(scrollableKey) {
  return {
    type: INCREMENT_INFINITE_SCROLL_PAGE,
    payload: { scrollableKey }
  };
}

export function reset(scrollableKey) {
  return {
    type: RESET_INFINITE_SCROLL,
    payload: { scrollableKey }
  };
}

export function setItems(scrollableKey, items) {
  return {
    type: SET_INFINITE_SCROLL_ITEMS,
    payload: { scrollableKey, items }
  };
}

export function appendItems(scrollableKey, items) {
  return {
    type: APPEND_INFINITE_SCROLL_ITEMS,
    payload: { scrollableKey, items }
  };
}

// updateFunction is a function that takes args:
// items, currentItems
export function updateItems(scrollableKey, updateFunction) {
  return {
    type: UPDATE_INFINITE_SCROLL_ITEMS,
    payload: { scrollableKey, updateFunction }
  };
}
