// BS needs to be imported before React and other imports:
// https://docs.bugsnag.com/platforms/browsers/react/#bundled
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';

import { pick } from 'dpl/shared/utils';

// eslint-disable-next-line
let BugsnagErrorBoundary = ({ children }) => children;

// HACK: BS degrades performance signficantly on /explore pages. not sure for
// cause but could be related to the page's heavy mem consumption, or frequent
// pushes to history state.
const isExplorePage = window.location.pathname.startsWith('/explore');

export function isThirdPartyScript(event) {
  try {
    const { hostname } = new URL(event.errors[0].stacktrace[0].file);
    return ![
      window.__DPL_HOST, // inline scripts
      window.__DPL_ASSET_HOST // scripts from our CDN
    ].includes(hostname);
  } catch (err) {
    return false;
  }
}

export function lowerSeverityIfNeeded(event) {
  if (event.severity !== 'error') {
    return;
  }

  if (window.__DPL_UNSUPPORTED_BROWSER) {
    event.severity = 'warning';
    event.addMetadata('severityReason', 'Unsupported browser');
    return;
  }

  if (window.__DPL_HOST !== window.location.hostname) {
    event.severity = 'warning';
    event.addMetadata('severityReason', 'Proxied request');
    return;
  }

  if (isThirdPartyScript(event)) {
    event.severity = 'warning';
    event.addMetadata('severityReason', 'Third-party script');
  }
}

if (CONFIG.bugsnagKey && !isExplorePage) {
  if (!window.bugsnagClient) {
    window.bugsnagClient = Bugsnag.start({
      releaseStage: CONFIG.bugsnagReleaseStage,
      apiKey: CONFIG.bugsnagKey,
      appVersion: CONFIG.appVersion,
      plugins: [new BugsnagPluginReact(React)],
      user: {
        id: window.__DPL_CURRENT_USER_ID
      },
      context: window.__DPL_PAGE_INFO?.category,
      onError(event) {
        lowerSeverityIfNeeded(event);
        event.addMetadata('debugSessionId', window.__DPL_DEBUG_SESSION_ID);
        event.addMetadata(
          'network',
          'connection',
          pick(window.navigator.connection || {}, ['downlink', 'effectiveType'])
        );
      }
    });
  }

  window.addEventListener('offline', () => {
    Bugsnag.addMetadata('network', 'wentOffline', new Date().toISOString());
  });

  window.addEventListener('online', () => {
    Bugsnag.addMetadata('network', 'wentOnline', new Date().toISOString());
  });

  BugsnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
}

export default BugsnagErrorBoundary;
