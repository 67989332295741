/* eslint-disable import/first, import/imports-first, import/order */
// BS needs to be imported before React and other imports:
// https://docs.bugsnag.com/platforms/browsers/react/#bundled
import BugsnagErrorBoundary from 'dpl/components/BugsnagErrorBoundary';

import React from 'react';

import initDevHooks from 'dpl/shared/utils/initDevHooks';

if (CONFIG.isDevelopment) {
  // eslint-disable-next-line global-require
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);

  initDevHooks();
}

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { ME_PATH } from 'dpl/shared/constants/urls';
import generateQueryKey from 'dpl/shared/fetching/utils/generateQueryKey';
import createQueryClient from 'dpl/shared/fetching/utils/createQueryClient';
import { getRandomStr } from 'dpl/shared/utils';
import createReduxStore from 'dpl/util/createReduxStore';
import GrowthBookContainer from 'dpl/analytics/components/GrowthBookContainer';

// create a single instance of QueryClient for all mounted apps
const queryClient = createQueryClient();

const RQ_DEVTOOLS_ENABLED = window.location.search.includes('rqdebug');
let RQ_DEVTOOLS_MOUNTED = false;

const CURRENT_USER_QUERY_KEY = generateQueryKey({ url: ME_PATH });
function setCurrentUserIfNeeded() {
  if (
    !window.__TPT_CONNECT_CURRENT_USER_DATA ||
    queryClient.getQueryData(CURRENT_USER_QUERY_KEY)
  ) {
    return;
  }

  const userData = window.__TPT_CONNECT_CURRENT_USER_DATA;
  queryClient.setQueryData(CURRENT_USER_QUERY_KEY, { data: userData });
}

export default function initReactApp(components = {}) {
  // allow us to reuse redux store across multiple packs
  let reduxStore = window.__DPL_REDUX_STORE;

  if (!reduxStore) {
    reduxStore = createReduxStore();
    window.__DPL_REDUX_STORE = reduxStore;

    if (!CONFIG.isProduction) {
      document.body.setAttribute(
        'data-test-react-query-in-flight',
        queryClient.isFetching()
      );
    }
  }

  window.__DPL_DEBUG_SESSION_ID ||
    (window.__DPL_DEBUG_SESSION_ID = getRandomStr());

  setCurrentUserIfNeeded();

  function createComponentTree(Component) {
    return (
      <BugsnagErrorBoundary>
        <Provider store={reduxStore}>
          <QueryClientProvider client={queryClient}>
            <GrowthBookContainer>
              <Component />
              {CONFIG.isDevelopment &&
                RQ_DEVTOOLS_ENABLED &&
                !RQ_DEVTOOLS_MOUNTED &&
                (() => {
                  RQ_DEVTOOLS_MOUNTED = true;
                  return <ReactQueryDevtools />;
                })()}
            </GrowthBookContainer>
          </QueryClientProvider>
        </Provider>
      </BugsnagErrorBoundary>
    );
  }

  Object.entries(components).forEach(([name, Component]) => {
    [
      ...document.querySelectorAll(`[data-react-mount-component="${name}"]`)
    ].forEach(mountEl => {
      ReactDOM.render(createComponentTree(Component), mountEl);
    });
  });
}

/* eslint-enable import/first, import/imports-first, import/order */
