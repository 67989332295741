import {
  INCREMENT_INFINITE_SCROLL_PAGE,
  SET_INFINITE_SCROLL_ITEMS,
  RESET_INFINITE_SCROLL,
  APPEND_INFINITE_SCROLL_ITEMS,
  UPDATE_INFINITE_SCROLL_ITEMS
} from 'dpl/actions/infinite_scroll';

const INFINITE_SCROLL_ACTIONS = [
  INCREMENT_INFINITE_SCROLL_PAGE,
  SET_INFINITE_SCROLL_ITEMS,
  RESET_INFINITE_SCROLL,
  APPEND_INFINITE_SCROLL_ITEMS,
  UPDATE_INFINITE_SCROLL_ITEMS
];

const INITIAL_STATE = {
  currentPage: 1,
  items: [],
  didSetItemsForPage: false
};

export default function infiniteScrollReducer(state = {}, action) {
  if (!INFINITE_SCROLL_ACTIONS.includes(action.type)) {
    return state;
  }

  const {
    type,
    payload: { items, scrollableKey, updateFunction }
  } = action;

  const prevState = state[scrollableKey] || INITIAL_STATE;

  if (type === INCREMENT_INFINITE_SCROLL_PAGE) {
    return {
      ...state,
      [scrollableKey]: {
        ...prevState,
        currentPage: prevState.currentPage + 1,
        didSetItemsForPage: false
      }
    };
  }

  if (type === RESET_INFINITE_SCROLL) {
    return {
      ...state,
      [scrollableKey]: INITIAL_STATE
    };
  }

  if (type === SET_INFINITE_SCROLL_ITEMS) {
    return {
      ...state,
      [scrollableKey]: {
        ...prevState,
        didSetItemsForPage: true,
        items
      }
    };
  }

  if (type === APPEND_INFINITE_SCROLL_ITEMS) {
    return {
      ...state,
      [scrollableKey]: {
        ...prevState,
        items: [...items, ...prevState.items]
      }
    };
  }

  if (type === UPDATE_INFINITE_SCROLL_ITEMS) {
    return {
      ...state,
      [scrollableKey]: {
        ...prevState,
        items: updateFunction(prevState.items)
      }
    };
  }

  return null;
}
