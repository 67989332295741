import {
  SET_PRISMIC_DOCUMENT_UID,
  SET_OVERVIEW_NAV_TAB_IDX
} from 'dpl/actions/server';

export default function serverReducer(state = {}, action) {
  const { payload, type } = action;

  if (type === SET_PRISMIC_DOCUMENT_UID || type === SET_OVERVIEW_NAV_TAB_IDX) {
    return {
      ...state,
      ...payload
    };
  }

  return state;
}
