import { TOGGLE_MOBILE_MENU } from 'dpl/actions/mobile_menu';
import { ORDERED_SEARCH_TYPES } from 'dpl/shared/constants/shared';

const DEFAULT_STATE = {
  openMobileMenuName: null,
  selectedSearchType: window.location.pathname.startsWith('/shelters')
    ? ORDERED_SEARCH_TYPES.SHELTERS
    : ORDERED_SEARCH_TYPES.BREEDERS
};

export default function mobileMenuReducer(state = DEFAULT_STATE, action) {
  if (action.type === TOGGLE_MOBILE_MENU) {
    const { mobileMenuName, selectedSearchType } = action.payload;

    return {
      ...state,
      openMobileMenuName: mobileMenuName,
      selectedSearchType
    };
  }

  return state;
}
