import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';

import { ANALYTICS_EVENTS } from 'dpl/constants/analytics';
import { sendAnalyticsEvent } from 'dpl/util/analytics';
import { removeEmptyValues } from 'dpl/shared/utils/object';
import {
  getLocalStorageValue,
  setLocalStorageValue
} from 'dpl/shared/utils/localStorage';

const gb = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: window.__DPL_GROWTHBOOK_KEY,
  enableDevMode: CONFIG.isDevelopment,
  trackingCallback: (experiment, result) => {
    const cacheKey = JSON.stringify([
      window.__GD_ANONYMOUS_ID || null,
      window.__GD_CURRENT_USER_ID || null,
      experiment.key,
      result.key,
      result.value
    ]);

    if (!getLocalStorageValue(cacheKey)) {
      sendAnalyticsEvent(ANALYTICS_EVENTS.EXPERIMENT_VIEWED_BY_USER, {
        experiment_id: experiment.key,
        variant_name: result.value
      });
      setLocalStorageValue(cacheKey, true);
    }
  }
});

export default function GrowthBookContainer({ children }) {
  const breedSlug = useSelector(({ server }) => server.breedSlug);

  useEffect(() => {
    gb.loadFeatures({ autoRefresh: true });
  }, []);

  useEffect(() => {
    gb.setAttributes(
      removeEmptyValues({
        anonymous_id: window.__GD_ANONYMOUS_ID || null,
        admin_user_id: window.__GD_CURRENT_ADMIN_USER_ID || null,
        breed_slug: breedSlug || null,
        user_id: window.__GD_CURRENT_USER_ID || null
      })
    );
  }, [breedSlug]);

  return <GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider>;
}

GrowthBookContainer.propTypes = {
  children: PropTypes.node.isRequired
};
