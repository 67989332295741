import {
  CLEAR_TOAST_NOTIFICATIONS,
  PUSH_TOAST_NOTIFICATION,
  REMOVE_TOAST_NOTIFICATION
} from 'dpl/shared/actions/toast_notifications';

const defaultState = {
  notifications: []
};

export default function toastNotificationsReducer(
  state = defaultState,
  action
) {
  const { payload, type } = action;

  if (type === CLEAR_TOAST_NOTIFICATIONS) {
    return {
      ...state,
      notifications: []
    };
  }

  if (type === PUSH_TOAST_NOTIFICATION) {
    return {
      ...state,
      notifications: [
        ...state.notifications,
        {
          ...payload
        }
      ]
    };
  }

  if (type === REMOVE_TOAST_NOTIFICATION) {
    return {
      ...state,
      notifications: state.notifications.filter(tn => tn.id !== payload)
    };
  }

  return state;
}
