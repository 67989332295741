import invariant from 'invariant';

import { QUERY_KEY_PREFIX_VALUES } from './constants';
import { fullUrl } from './fullUrl';

export default function generateQueryKey({ params, prefix, url }) {
  invariant(url, "generateQueryKey: requires 'url'");

  // add a version tag to the url params to bust any browser caching
  // whenever a new version of the app is deployed
  const updatedParams = params ?? {};
  if (!CONFIG.isTest) {
    updatedParams.__version = CONFIG.appVersion;
  }

  // ensure we don't have query parameters in the base url
  if (/\?[^#]/.test(url)) {
    // eslint-disable-next-line no-console
    console.warn(
      "generateQueryKey: url parameters should be passed as 'params' for optimal caching"
    );
  }

  // ensure we don't introduce an ad-hoc prefix
  prefix = Array.isArray(prefix) ? prefix : [prefix];
  if (prefix[0]) {
    const invalidPrefixes = prefix.filter(
      p => !QUERY_KEY_PREFIX_VALUES.includes(p)
    );
    invariant(
      invalidPrefixes.length === 0,
      `Invalid prefixes [${invalidPrefixes.join(
        ', '
      )}] not present in QUERY_KEY_PREFIX_VALUES`
    );
  }

  return [...prefix, fullUrl(url, updatedParams)].filter(Boolean);
}
