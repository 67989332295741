export const CLEAR_TOAST_NOTIFICATIONS = 'CLEAR_TOAST_NOTIFICATIONS';
export const PUSH_TOAST_NOTIFICATION = 'PUSH_TOAST_NOTIFICATION';
export const REMOVE_TOAST_NOTIFICATION = 'REMOVE_TOAST_NOTIFICATION';

export function clearToastNotifications() {
  return {
    type: CLEAR_TOAST_NOTIFICATIONS
  };
}

export function removeToastNotification(toastNotification) {
  const { id, timeoutId } = toastNotification;
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
  return {
    type: REMOVE_TOAST_NOTIFICATION,
    payload: id
  };
}

export function pushToastNotification(toastNotification) {
  toastNotification = {
    ...toastNotification,
    id: new Date().getTime()
  };
  return function fadeNotification(dispatch) {
    // if timeout is undefined, default it to 12 seconds
    // if timeout has a time passed in, use that time
    // if timeout is false, do not time out the notification
    const { timeout } = toastNotification;
    const setToastTimeout =
      Number.isInteger(timeout) || typeof timeout === 'undefined';
    if (setToastTimeout) {
      const timeoutTime = timeout || 12000;
      const timeoutId = window.setTimeout(() => {
        dispatch(removeToastNotification(toastNotification));
      }, timeoutTime);
      toastNotification = {
        ...toastNotification,
        timeoutId
      };
    }
    return dispatch({
      type: PUSH_TOAST_NOTIFICATION,
      payload: toastNotification
    });
  };
}
