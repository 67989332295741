import { LOCATION_CHANGE } from 'react-router-redux';

import { parseQueryString } from 'dpl/util/queryString';

export default function queryParams(state = {}, action) {
  if (action.type === LOCATION_CHANGE) {
    const searchQueryParams = parseQueryString(action.payload.search);
    const hashQueryParams = parseQueryString(action.payload.hash);

    return {
      ...hashQueryParams,
      ...searchQueryParams
    };
  }

  return state;
}
