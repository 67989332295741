import { QueryClient } from 'react-query';

export const QUERY_CLIENT_CONFIG = {
  queries: {
    retry: 0,
    cacheTime: Infinity, // how long to keep in cache even if not mounted
    refetchOnWindowFocus: false,
    staleTime: Infinity // how long before resource is marked as stale (needs refetch)
  }
};

export default function createQueryClient(options = {}) {
  return new QueryClient({
    defaultOptions: {
      ...QUERY_CLIENT_CONFIG,
      ...options
    }
  });
}
