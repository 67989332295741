export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU';

export function toggleMobileMenu(mobileMenuName, selectedSearchType) {
  return {
    type: TOGGLE_MOBILE_MENU,
    payload: {
      mobileMenuName,
      selectedSearchType
    }
  };
}
