import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function Icon({
  className,
  height,
  name,
  width,
  style,
  onTransitionEnd
}) {
  return (
    <svg
      className={classnames(`gd-icon gd-icon-${name} db`, className)}
      style={{
        ...style,
        height,
        minHeight: height,
        minWidth: width,
        width
      }}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      onTransitionEnd={onTransitionEnd}
    >
      <use xlinkHref={`#icon-${name}`} />
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  onTransitionEnd: PropTypes.func,
  style: PropTypes.object // eslint-disable-line react/forbid-prop-types
};

Icon.defaultProps = {
  className: '',
  height: null,
  width: null,
  onTransitionEnd: null,
  style: null
};
