import url from 'url';

import qs from 'qs';

/**
 * Handles the difference between querystring and search/hash string.
 * The latter includes the question mark/pound sign which the querystring
 * module does not account for by default
 */
export function parseQueryString(queryString) {
  if (/^[?#]/.test(queryString)) {
    queryString = queryString.substr(1);
  }
  return qs.parse(queryString);
}

/**
 * Returns new querystring w/out overriding current params
 * leaves urls that came from server w/ params unchanged
 */
export function addToQuery(queryParams, originalQueryString) {
  return qs.stringify(
    {
      ...parseQueryString(originalQueryString),
      ...queryParams
    },
    {
      arrayFormat: 'brackets'
    }
  );
}

export function removeFromQuery(queryKeys, queryString) {
  const currentQueryParams = parseQueryString(queryString);
  [].concat(queryKeys).forEach(key => {
    delete currentQueryParams[key];
  });
  return qs.stringify(currentQueryParams, {
    arrayFormat: 'brackets'
  });
}

export function removeFromUrl(queryKeys, urlString) {
  const parsed = url.parse(urlString);
  return url.format({
    ...parsed,
    search: removeFromQuery(queryKeys, parsed.search || '')
  });
}

export function addPathFragment(
  { pathname, query, hash },
  originalPath = window.location.href
) {
  const parsed = url.parse(originalPath);
  return url.format({
    pathname: pathname || parsed.pathname,
    search: query || parsed.search,
    hash: hash || parsed.hash
  });
}

export function addQueryToUrl(queryParams, originalUrl) {
  const parsed = url.parse(originalUrl);
  return url.format({
    ...parsed,
    search: addToQuery(queryParams, parsed.search || '')
  });
}

export function getSafeRedirectPath(queryString = window.location.search) {
  const { redirect_to: redirectTo } = parseQueryString(queryString);
  if (!(redirectTo && redirectTo.startsWith('/'))) {
    return null;
  }

  return redirectTo;
}
