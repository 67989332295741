export default function initDevHooks() {
  // HACK: ignore React.StrictMode deprecation warnings
  // while we work on them...eventually?
  if (!CONFIG.reactStrictMode) {
    const _warn = window.console.warn;
    window.console.warn = (...args) => {
      const [msg] = args;

      if (
        !JSON.stringify(msg).includes(
          'has been renamed, and is not recommended for use.'
        )
      ) {
        _warn.call(window.console, ...args);
      }
    };
  }

  const _error = window.console.error;
  window.console.error = (...args) => {
    const [msg] = args;

    if (
      typeof msg !== 'string' ||
      !msg.includes(
        'Warning: Failed prop type: Invalid prop `component` of type `object` supplied to `Route`, expected `function`.'
      )
    ) {
      _error.call(window.console, ...args);
    }
  };
}
