export const SET_PRISMIC_DOCUMENT_UID = 'SET_PRISMIC_DOCUMENT_UID';
export const SET_OVERVIEW_NAV_TAB_IDX = 'SET_OVERVIEW_NAV_TAB_IDX';

export function setPrismicDocumentUid(uid) {
  return {
    type: SET_PRISMIC_DOCUMENT_UID,
    payload: {
      prismicDocumentUid: uid
    }
  };
}

export function setBreedOverviewNavTabIdx(idx) {
  return {
    type: SET_OVERVIEW_NAV_TAB_IDX,
    payload: {
      overviewNavTabIdx: idx
    }
  };
}
