import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import { batchedSubscribe } from 'redux-batched-subscribe';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import {
  routerMiddleware,
  routerReducer,
  syncHistoryWithStore
} from 'react-router-redux';
import { createBrowserHistory } from 'history';

import { parseQueryString } from 'dpl/util/queryString';
import queryParamsReducer from 'dpl/reducers/query_params';
import mobileMenuReducer from 'dpl/reducers/mobile_menu';
import toastNotificationsReducer from 'dpl/shared/reducers/toast_notifications';
import infiniteScrollReducer from 'dpl/reducers/infinite_scroll';
import serverReducer from 'dpl/reducers/server';

export default function createReduxStore(initialState) {
  // NOTE: this is not the same history obj used by react-router
  const history = createBrowserHistory();

  initialState = {
    queryParams: {
      ...parseQueryString(window.location.hash),
      ...parseQueryString(window.location.search)
    },
    ...(initialState || window.__DPL_INITIAL_STATE || {})
  };

  initialState.server = {
    ...(initialState.server || {}),
    ...(window.__DPL_TRUST_ITEMS || {})
  };

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  let reduxStore = null;

  const reduxMiddleware = composeEnhancers(
    applyMiddleware(routerMiddleware(history), thunk),
    // for some reason batchedUpdates is not a func in NODE_ENV=test
    batchedSubscribe(batchedUpdates || (notify => notify()))
  );

  reduxStore = createStore(
    combineReducers({
      routing: routerReducer,
      queryParams: queryParamsReducer,
      mobileMenu: mobileMenuReducer,
      toastNotifications: toastNotificationsReducer,
      infiniteScroll: infiniteScrollReducer,
      // used to communicate btwn server and redux state
      server: serverReducer
    }),
    initialState,
    reduxMiddleware
  );

  syncHistoryWithStore(history, reduxStore);

  return reduxStore;
}
